import React from 'react';

import { FormattedMessage } from '../../../util/reactIntl';
import { formatMoney, formatMoneyRoundWithoutDecimals } from '../../../util/currency';
import { types as sdkTypes } from '../../../util/sdkLoader';

const { Money } = sdkTypes;

import { Heading } from '../../../components';
import AzureImageDisplay from '../../../components/AzureImageDisplay/AzureImageDisplay';


import css from './StoreDetailsSection.module.css';

const StoreDetailsSection = props => {
    const { currentListing, rootClassName, intl, marketplaceCurrency, listingPage } = props;

    // Ensure currentListing is defined
    const attributes = currentListing?.attributes || {};
    const publicData = attributes.publicData || {};
    const title = attributes.title || '';
    const description = attributes.description || '';

    // const { publicData, title, description } = currentListing?.attributes;
    const { location, openingHours, storeSize, measureUnit, productPriceRange, teamMembers, productGroup, productType, storeLabels } = publicData;

    // Ensure openingHours is defined and is an object
    const validOpeningHours = openingHours && typeof openingHours === 'object';
    // Define the order of the days
    const dayOrder = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
    // Convert the object to an array of entries and sort by the defined order
    const sortedDays = validOpeningHours ? Object.entries(openingHours).sort(
        ([dayA], [dayB]) => dayOrder.indexOf(dayA) - dayOrder.indexOf(dayB)
    ) : [];
    const mappedHours = sortedDays.map(([day, times], index) => {
        const openTime = times.open === 'Closed' ? 'Closed' : `${times.open} - ${times.close}`;
        return (
            <div className={css.schedule} key={index}>
                <label>{day.charAt(0).toUpperCase() + day.slice(1)}</label>
                <span>{openTime}</span>
            </div>
        )
    });

    const formattedMeasureUnite = measureUnit === 'm2' ? 'm²' : "ft²";

    const formattedPriceRange = productPriceRange ? (
        `${formatMoneyRoundWithoutDecimals(intl, new Money(productPriceRange.minPrice.amount, marketplaceCurrency))} - ${formatMoneyRoundWithoutDecimals(intl, new Money(productPriceRange.maxPrice.amount, marketplaceCurrency))}`
    ) : null;

    return (
        <div className={rootClassName || css.root}>
            {listingPage &&
                <Heading as="h2" rootClassName={css.sectionHeading}>
                    <FormattedMessage id="StoreDetailsSection.aboutTitle" values={{ title }} />
                </Heading>
            }
            <div className={css.address}>
                <label className={css.mainLabel}>
                    <FormattedMessage id="StoreDetailsSection.addressLabel" />
                </label>
                {location?.address}
            </div>
            <div className={css.size}>
                <label className={css.mainLabel}>
                    <FormattedMessage id="StoreDetailsSection.storeSizeLabel" />
                </label>
                {storeSize + " " + formattedMeasureUnite}
            </div>
            {openingHours &&
                <div>
                    <label className={css.mainLabel}>
                        <FormattedMessage id="StoreDetailsSection.scheduleLabel" />
                    </label>
                    {mappedHours}
                </div>
            }

            {listingPage &&
                <div className={css.description}>
                    {description}
                </div>
            }
            {productGroup &&
                <div className={css.groups}>
                    <label className={css.mainLabel}>
                        <FormattedMessage id="StoreDetailsSection.productGroupLabel" />
                    </label>
                    {productGroup
                        .map(item => item.charAt(0).toUpperCase() + item.slice(1))
                        .join(', ')}
                </div>
            }
            {productType &&
                <div className={css.groups}>
                    <label className={css.mainLabel}>
                        <FormattedMessage id="StoreDetailsSection.productTypeLabel" />
                    </label>
                    {productType
                        .map(item => item.charAt(0).toUpperCase() + item.slice(1))
                        .join(', ')}
                </div>
            }
            {productPriceRange &&
                <div className={css.groups}>
                    <label className={css.mainLabel}>
                        <FormattedMessage id="StoreDetailsSection.priceRangeLabel" />
                    </label>
                    {formattedPriceRange}
                </div>
            }
            {storeLabels &&
                <div className={css.groups}>
                    <label className={css.mainLabel}>
                        <FormattedMessage id="StoreDetailsSection.brandPreferencesLabel" />
                    </label>
                    {storeLabels
                        .map(item => item.charAt(0).toUpperCase() + item.slice(1))
                        .join(', ')}
                </div>
            }
            {teamMembers &&
                <div className={css.groups} style={{ marginTop: 16, alignItems: "flex-start" }}>
                    <label className={css.mainLabel}>
                        <FormattedMessage id="StoreDetailsSection.teamMembersLabel" />
                    </label>
                    <div className={css.membersContainer}>
                        {teamMembers.map((member, index) => {
                            return (
                                <div className={css.member}>
                                    <AzureImageDisplay
                                        value={member.image}
                                        isRounded />
                                    <span style={{ marginTop: 16 }}>
                                        {member.teamMemberRole}
                                    </span>
                                    <span>
                                        {member.teamMemberName}
                                    </span>
                                </div>
                            )
                        })}
                    </div>
                </div>
            }
        </div>
    )
};

export default StoreDetailsSection;
